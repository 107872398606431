/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, ContactForm, Image, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"RSVP"}>
        <SiteHeader set="" currentLanguage={2} />

        <Column className="--center pb--80 pt--80" name={"potvrdit-ucast-2"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" style={{"maxWidth":1090}} anim={"2"} animS={"5"} columns={"1"}>
            
            <ColumnWrapper className="pt--40">
              
              <Title className="title-box title-box--center fs--36 mt--10" content={"Please confirm your attendance"}>
              </Title>

              <Text className="text-box ff--1 fs--18" style={{"maxWidth":600}} content={"Accommodation is provided for all guests from outside of Prague.\n<br><br>&nbsp;Will you experience our magical wedding with us?<br>Please fill in the information below.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--0 pt--0" name={"potvrdit-ucast"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style3 ff--2 fs--16" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Name & Surname","type":"text","required":true,"placeholder":"Enter your whole name"},{"name":"Other guests","type":"textarea","placeholder":"How many people, their names, for children please provide their age"},{"name":"Alergies","type":"text","required":true,"placeholder":"Do you have any dietary restrictions?"},{"name":"Email","type":"email","required":true,"placeholder":"E-mail"},{"name":"Address","type":"text","required":true,"placeholder":"Postal address"},{"name":"Favourite song","type":"text","placeholder":"The name of your favorite song & artist"},{"name":"Send","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--60" name={"fotogalerie"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" style={{"maxWidth":1150}} anim={"2"} animS={"5"} columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box" content={"In case of further questions, please contact our wedding coordinator.<br>Eva: eva@ifconcept.cz"}>
              </Text>

              <Image style={{"maxWidth":""}} src={"https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=860x_.png"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=350x_.png 350w, https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=660x_.png 660w, https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=860x_.png 860w, https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=1400x_.png 1400w, https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=2000x_.png 2000w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"paddingTop":0,"paddingBottom":1}} name={"paticka"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" style={{"maxWidth":1090}} anim={"2"} animS={"5"} columns={"1"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 fs--30 mt--16" innerClassName="pb--02 pl--0 pr--0 pt--0" href={"/home_en"} content={"Save the Date"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 fs--30" innerClassName="pb--02 pl--0 pr--0 pt--0" href={"/en/rsvp"} content={"RSVP"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--40" name={"paticka-2"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1090}} anim={null} animS={null} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--36 mt--10" content={"We look forward to you!"}>
              </Title>

              <Text className="text-box text-box--center mt--10" content={"<a href=\"/potvrdit-ucast\">Petra &amp; Petr</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}